<template>
  <k-field-group language-prefix="priceAgreement.fields">
    <k-form-dialog
      v-bind="$attrs"
      :panels="panels"
      v-on="$listeners"
    >
      <template #panel.0>
        <KTextField
          v-model="values.name"
          field="name"
          grid="col-sm-4 pr-2"
          required
        />
        <v-tooltip
          :disabled="!isUpdateForm"
          right
          nudge-top="10"
        >
          <template #activator="{ on, attrs }">
            <div
              v-bind="attrs"
              class="price-agreement-type"
              v-on="on"
            >
              <KRadioGroup
                v-model="values.priceAgreementTypeId"
                :items="priceAgreementTypes"
                field="priceAgreementTypeId"
                :disabled="isUpdateForm"
                row
              />
            </div>
          </template>
          <span>{{ $t('priceAgreement.messages.changingTypeDisabled') }}</span>
        </v-tooltip>
      </template>
    </k-form-dialog>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KFormDialog from '@/components/crud/KFormDialog.vue';
import KRadioGroup from '@/components/crud/fields/KRadioGroup.vue';
import priceAgreementType from '@/application/enums/priceAgreementType.js';

export default {
  name: 'PriceAgreementForm',
  components: {
    KRadioGroup,
    KFieldGroup,
    KTextField,
    KFormDialog,
  },
  props: {
    values: {
      type: Object,
    },
    isUpdateForm: {
      type: Boolean,
    },
  },
  computed: {
    panels() {
      return [
        { name: this.$t('global.defaultTabName') },
      ];
    },
    priceAgreementTypes() {
      return Object.keys(priceAgreementType).map(type => {
        return {
          value: priceAgreementType[type],
          text: this.$t(`priceAgreement.priceAgreementType.${type}`),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.price-agreement-type {
  width: max-content;
}
</style>
