<template>
  <div id="page-priceAgreements-list">
    <KCrudLayout :search.sync="searchQuery">
      <template #header>
        {{ $tc('priceAgreement.title', 2) }}
      </template>
      <template #flow-actions>
        <v-btn
          color="primary"
          depressed
          tile
          :disabled="priceAgreementCanPlaceFailureReasons.length > 0"
          @click="createDialog = true"
        >
          {{ $t('actions.createResource', { resource: $tc('priceAgreement.title', 1) }) }}
        </v-btn>
        <PriceAgreementForm
          v-model="createDialog"
          :request="createRequest"
          :title="$tc('priceAgreement.title',1)"
          :values="createFormValues"
          @change="$refs.table.reload()"
        />
      </template>
      <template #view.list>
        <v-row v-if="priceAgreementCanPlaceFailureReasons.length > 0">
          <v-col cols="12">
            <actionable-alert
              :message="$t('actionables.messages.priceAgreementActionableFailed')"
              :failure-reasons="priceAgreementCanPlaceFailureReasons"
            >
              <template #actions>
                <div>
                  <v-btn
                    class="mx-1"
                    color="primary"
                    depressed
                    tile
                    @click="openUpdateEmployer"
                  >
                    {{ $t('actions.updateResource', { resource: $tc('employer.title') }) }}
                  </v-btn>
                  <EmployerForm
                    v-model="employerUpdateDialog"
                    :request="updateEmployerRequest"
                    :title="$tc('employer.title')"
                    :values="employerToUpdate"
                    is-update-form
                    @change="checkCanBePlacedActionable"
                  />
                </div>
              </template>
            </actionable-alert>
          </v-col>
        </v-row>
        <k-crud-table
          ref="table"
          :headers="crudHeaders"
          :index-request="indexRequest"
          :search="searchQuery"
          language-prefix="priceAgreement.fields"
          resource="priceAgreement"
          :can-delete="canDelete"
          @click:row="$router.push({ name: 'employer.priceAgreement.show', params: { employerId: employer.id, priceAgreementId: $event.id } })"
          @click:copy="copyAgreement"
          @click:edit="openUpdate"
          @click:delete="deletePriceAgreement"
        >
          <template #item.priceAgreementTypeId="{ item }">
            {{ getPriceAgreementTypeTranslation(item.priceAgreementTypeId) }}
          </template>
        </k-crud-table>
      </template>
    </KCrudLayout>
    <PriceAgreementForm
      v-model="updateDialog"
      :request="updateRequest"
      :title="$tc('priceAgreement.title',1)"
      :values="updateFormValues"
      is-update-form
      @change="$refs.table.reload()"
    />
  </div>
</template>

<script>
import eventBus from '@/application/eventBus.ts';
import PriceAgreement from '@/application/models/PriceAgreement.js';
import KCrudTable from '@/components/crud/KCrudTable.old.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { create, index, show, update, remove, copy } from '@/modules/priceAgreements/api/priceAgreement.js';
import PriceAgreementForm from '@/modules/priceAgreements/components/PriceAgreementForm.vue';
import priceAgreementType from '@/application/enums/priceAgreementType.js';
import Employer from '@/application/models/Employer.js';
import { show as showEmployer, update as updateEmployer } from '@/modules/employer/api/employer.js';
import ActionableAlert from '@/modules/actionable/views/ActionableAlert.vue';
import EmployerForm from '@/modules/employer/components/EmployerForm.vue';
import { actionableCanCreatePriceAgreement } from '@/modules/employer/api/employer.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AgreementsTable',
  components: {
    KCrudTable,
    PriceAgreementForm,
    KCrudLayout,
    ActionableAlert,
    EmployerForm,
  },
  props: {
    employer: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      searchQuery: '',
      updateDialog: false,
      createDialog: false,
      updateFormValues: new PriceAgreement(),
      createFormValues: new PriceAgreement(),
      priceAgreementCanPlaceFailureReasons: [],
      employerUpdateDialog: false,
      employerToUpdate: new Employer(),
    };
  },
  computed: {
    ...mapGetters({
      can: 'authorisation/can',
    }),
    crudHeaders() {
      return [
        {
          value: 'name',
          language: 'name',
        },
        {
          value: 'priceAgreementTypeId',
          language: 'priceAgreementTypeId',
        },
      ];
    },
  },
  watch: {
    '$route.params.employerId': {
      immediate: true,
      handler: async function () {
        await this.checkCanBePlacedActionable();
      },
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'employer.index' },
        text: this.$tc('employer.title', 2),
      },
      {
        exact: true,
        to: { name: 'employer.show' },
        text: this.employer.name,
      },
      {
        exact: true,
        to: { name: 'employer.priceAgreement' },
        text: this.$tc('priceAgreement.title', 2),
      },
    ]);
  },
  methods: {
    updateRequest() {
      return update(this.updateFormValues);
    },
    canDelete(item) {
      return item.isDeletable;
    },
    async deletePriceAgreement(priceAgreement) {
      try {
        eventBus.$emit('confirm', {
          title: this.$t('actions.deleteConfirmation.title'),
          body: this.$t('actions.deleteConfirmation.bodyAlt', {
            resource: this.$tc('priceAgreement.title', 1).toLowerCase(),
          }),
          confirmCallback: async () => {
            await remove(priceAgreement.id);
            this.$refs.table.reload();
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('actions.deleteResource', { resource: this.$tc('priceAgreement.title', 1) }),
            });
          },
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
        throw error;
      }
    },
    async createRequest() {
      const response = await create(this.$route.params.employerId, this.createFormValues);
      await this.$router.push({
        name: 'employer.priceAgreement.periods',
        params: {
          priceAgreementId: response.data.data.id,
          openCreateDialog: true,
        },
      });
    },
    indexRequest() {
      return index(this.$route.params.employerId, ...arguments);
    },
    async copyAgreement(item) {
      try {
        eventBus.$emit('confirm', {
          title: this.$t('actions.copyConfirmation.title'),
          body: this.$t('actions.copyConfirmation.bodyAlt', {
            resource: this.$tc('priceAgreement.title', 1).toLowerCase(),
          }),
          confirmCallback: async () => {
            await copy(item.id);
            this.$refs.table.reload();
            eventBus.$emit('snackbar', {
              color: 'success',
              text: this.$t('actions.copyResource', { resource: this.$tc('priceAgreement.title', 1) }),
            });
          },
        });
      } catch (error) {
        eventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.generic'),
        });
      }
    },
    async checkCanBePlacedActionable() {
      // @TODO Change api call to actual check actionable (when api is ready)
      const response = await actionableCanCreatePriceAgreement(this.employer.id);
      this.priceAgreementCanPlaceFailureReasons = response.data.reasons;
    },
    async openUpdateEmployer() {
      this.employerToUpdate = new Employer();
      const response = await showEmployer(this.employer.id);
      this.employerToUpdate.mapResponse(response.data.data);
      this.employerUpdateDialog = true;
    },
    updateEmployerRequest() {
      return updateEmployer(this.employerToUpdate);
    },
    async openUpdate(item) {
      this.updateFormValues = new PriceAgreement();
      const priceAgreementShowRequest = await show(item.id);
      this.updateFormValues.mapResponse(priceAgreementShowRequest.data.data);
      this.updateDialog = true;
    },
    getPriceAgreementTypeTranslation(id) {
      return this.$t(`priceAgreement.priceAgreementType.${Object.keys(priceAgreementType)
        .find(key => priceAgreementType[key] === id)}`);
    },
  },
};
</script>
