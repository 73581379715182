import Model from '@/application/models/model.js';

class PriceAgreement extends Model {
  id = 0;
  name = '';
  priceAgreementTypeId = 0;

  mapForRequest() {
    return {
      name: this.name,
      priceAgreementTypeId: this.priceAgreementTypeId,
    };
  }
}

export default PriceAgreement;
